import React from "react";

import { Router } from "@gatsbyjs/reach-router";

import shallow from "zustand/shallow";

import { Permission, useHoyerAcl } from "../../../../../hooks/useHoyerAcl";
import FuelPriceList from "../../components/FuelPriceList";
import useStore from "../store";
import Cards from "./Cards";
import CardsCreate from "./CardsCreate";
import CardsUser from "./CardsUser";
import HoyerPayRequests from "./HoyerPayRequests";
import HoyerPayVehicles from "./HoyerPayVehicles";
import ServiceModules from "./ServiceModules";
import TransactionInvoices from "./TransactionInvoices";
import TransactionRefueling from "./TransactionRefueling";

const DashboardRouter = ({
    canManageHoyerPay,
}: {
    canManageHoyerPay: boolean;
}) => {
    const { can } = useHoyerAcl();
    const [data, selectedCustomerNumber] = useStore(
        (state) => [state.data, state.selectedCustomerNumber],
        shallow
    );

    return (
        <Router>
            {can(Permission.INVOICE_LIST) && (
                <TransactionInvoices path="invoices" />
            )}

            {can(Permission.TRANSACTION_LIST) && (
                <TransactionRefueling path="refueling" />
            )}

            {can(Permission.CARD_LIST) && <Cards path="cards/cards" />}

            {can(Permission.CARD_DYN_PIN) && <CardsUser path="cards/user" />}

            {can(Permission.CARD_CREATE_NEW) && (
                <CardsCreate path="cards/create/" />
            )}

            {canManageHoyerPay && (
                <>
                    <HoyerPayRequests path="hoyer-pay/requests" />
                    <HoyerPayVehicles path="hoyer-pay/vehicles" />
                    {/*<HoyerPayPersons path="hoyer-pay/persons" />*/}
                </>
            )}

            {can(Permission.SERVICE_LIST) && (
                <ServiceModules path="customer-data" />
            )}

            {data &&
                selectedCustomerNumber &&
                data[selectedCustomerNumber].canViewAdBluePriceList && (
                    <FuelPriceList
                        productName="AdBlue®"
                        documentDownloadRoute="/hoyercard-portal/fuel-price-table/adblue/download"
                        priceSheet={
                            data[selectedCustomerNumber].adBluePrices ?? null
                        }
                        path="ad-blue"
                        disclaimerContent={
                            <>
                                <span>*Disclaimer - Haftungsausschluss </span>
                                <br />
                                1. Gültig an allen Hoyer Tankstellen sowie an
                                teilnehmenden Tankstellen mit Akzeptanz der
                                Hoyer Card.
                                <br />
                                2. Alle Angaben innerhalb dieser Preisliste sind
                                ohne Gewähr.
                                <br />
                                3. Alle hier aufgeführten Preise sind
                                Nettopreise exkl. Mehrwertsteuer.
                            </>
                        }
                    />
                )}

            {data &&
                selectedCustomerNumber &&
                data[selectedCustomerNumber].canViewAdBluePriceList && (
                    <FuelPriceList
                        productName="HVO 100"
                        documentDownloadRoute="/hoyercard-portal/fuel-price-table/hvo100/download"
                        priceSheet={
                            data[selectedCustomerNumber].hvo100Prices ?? null
                        }
                        path="hvo-100"
                        disclaimerContent={
                            <>
                                <span>*Disclaimer - Haftungsausschluss </span>
                                <br />
                                1. Gültig an allen Hoyer Tankstellen. Abnahme
                                mittels Hoyer Card.
                                <br />
                                2. Alle Angaben innerhalb dieser Preisliste sind
                                ohne Gewähr.
                                <br />
                                3. Alle hier aufgeführten Preise sind
                                Nettopreise exkl. Mehrwertsteuer.
                            </>
                        }
                    />
                )}
        </Router>
    );
};

export default DashboardRouter;
