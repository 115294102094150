import React from "react";

import { css, useTheme } from "@emotion/react";

import { Permission, useHoyerAcl } from "../../../../../../hooks/useHoyerAcl";
import Headline from "../../../../../base/atoms/Headline";
import LinkComponent from "../../../../../shared/LinkComponent";
import Box from "../../../components/Box";

const UnlockCustomerNumbers = () => {
    const { canAny } = useHoyerAcl();
    const theme = useTheme();

    const buttonStyle = css`
        color: ${theme.colors.white.toString()};
        background-color: ${theme.colors.red.toString()};
        :hover {
            color: ${theme.colors.white.toString()};
            filter: brightness(85%);
        }
    `;

    if (!canAny([Permission.CUSTOMER_ADMIN_LIST])) {
        return <></>;
    }

    return (
        <Box>
            <Headline>Kunden-Administratoren verwalten</Headline>
            <p>
                Benutzer können zu Administratoren von Kundenkontos gemacht
                werden. Administratoren können u.a. Funktionen der
                Zahlungssicherheit selbständig verwalten.
            </p>
            <LinkComponent
                color="red"
                button
                fullWidth
                link={{
                    text: "Administratoren verwalten",
                    href: "/hoyercard-portal/admin/customer-admin",
                }}
                css={buttonStyle}
            />
        </Box>
    );
};

export default UnlockCustomerNumbers;
