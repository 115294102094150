import React, { ReactNode, useMemo } from "react";

import styled from "@emotion/styled";
import shallow from "zustand/shallow";

import FilterGrid from "../../components/FilterGrid";
import { Headline } from "../../components/Headline";
import useStore from "../store";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    margin-top: 30px;
    margin-bottom: 30px;
`;

const HeadlineContainer = () => {
    const [selectedSection, data] = useStore(
        (state) => [state.selectedSection, state.data],
        shallow
    );

    const FirstComponent: ReactNode | undefined = useMemo(() => {
        return selectedSection.filter?.firstComponent;
    }, [selectedSection]);

    return (
        <>
            {data && !selectedSection.dontShowHeader && (
                <Container>
                    <Headline>{selectedSection.groupDisplayName}</Headline>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/*@ts-ignore*/}
                    {FirstComponent && <FirstComponent />}
                    <FilterGrid />
                </Container>
            )}
        </>
    );
};

export default HeadlineContainer;
