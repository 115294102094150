import React, { ChangeEvent, ReactNode, useMemo } from "react";

import { useLocation } from "@gatsbyjs/reach-router";

import styled from "@emotion/styled";
import shallow from "zustand/shallow";

import StorageHandler from "../../../../../utils/StorageHandler";
import NewSelect from "../../../../shared/forms/NewSelect";
import useStore, {
    CustomerId,
    iHoyerCardCustomer,
} from "../../Dashboard/store";

const storage = new StorageHandler();

export const getTextForSelect = (customer: iHoyerCardCustomer) => {
    let text = `${customer.customer_number}`;
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    text += ` - ${customer.firstname} ${customer.lastname}`;
    if (customer.additional_name) {
        text += ` ${customer.additional_name}`;
    }

    return text;
};

const Flex = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-items: center;
    width: 100%;
    flex-direction: column;
    ${({ theme }) =>
        theme.breakpoints.from.medium.css`
            flex-direction: row;
    `}
`;

/**
 * Don't show the Customer Select if the fuel type Tables are visited.
 * Because it only applied to the currently selected Customer.
 * @param route
 */
const isCustomerSelectVisible = (route: string): boolean => {
    const excludedRoutes = [
        "/hoyercard-portal/hvo-100/",
        "/hoyercard-portal/ad-blue/",
    ];
    return !excludedRoutes.includes(route);
};

const FilterGrid = () => {
    const [
        loading,
        loadingSpecific,
        data,
        customers,
        selectedCustomerNumber,
        changeCustomer,
    ] = useStore(
        (state) => [
            state.loading,
            state.loadingSpecific,
            state.data,
            state.customers,
            state.selectedCustomerNumber,
            state.changeCustomer,
        ],
        shallow
    );
    const location = useLocation();

    const [selectedSection] = useStore(
        (state) => [state.selectedSection],
        shallow
    );

    const Component: ReactNode | undefined = useMemo(() => {
        return selectedSection.filter?.component;
    }, [selectedSection]);

    const SearchComponent: ReactNode | undefined = useMemo(() => {
        return selectedSection.filter?.searchBar;
    }, [selectedSection]);

    const SortComponent: ReactNode | undefined = useMemo(() => {
        return selectedSection.filter?.sortComponent;
    }, [selectedSection]);

    const handleCustomerNumberSelect = (customerNumber: CustomerId) => {
        storage.set("selectedCustomerNumber", customerNumber);
        changeCustomer(customerNumber);
    };

    return (
        <>
            {!loading &&
                !loadingSpecific &&
                data !== null &&
                isCustomerSelectVisible(location.pathname) && (
                    <NewSelect
                        name="customerNumber"
                        fullwidth
                        value={selectedCustomerNumber}
                        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                            handleCustomerNumberSelect(
                                event.target.value as unknown as CustomerId
                            );
                        }}
                        options={customers?.map((customer) => ({
                            value: customer.customer_number,
                            text: getTextForSelect(customer),
                        }))}
                    />
                )}
            <Flex>
                {SearchComponent && <SearchComponent />}
                {SortComponent && <SortComponent />}
                {Component && <Component />}
            </Flex>
        </>
    );
};

export default FilterGrid;
