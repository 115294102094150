import React, { useMemo } from "react";

import styled from "@emotion/styled";
import classNames from "classnames";
import shallow from "zustand/shallow";

import { useHoyerAcl } from "../../../../../../hooks/useHoyerAcl";
import { activeLink } from "../../../../../shared/activeLink";
import { navigate } from "../../../../../shared/LinkComponent";
import useStore, { sections } from "../../store";
import OpenOnboardingRequestBadge from "./OpenOnboardingRequestBadge";

const ChildrenTabs = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    ${({ theme }) =>
        theme.breakpoints.from.medium.css`
            flex-direction: row;
            gap: 20px;
            margin-top: 3em;
            margin-bottom: 0;
    `}
`;

const ChildTab = styled.div`
    position: relative;
    all: unset;
    display: flex;
    align-items: center;
    font-size: 1.1em;
    font-weight: 900;
    padding: 5px;
    ${activeLink}

    &:hover {
        color: ${({ theme }) => theme.colors.red.toString()};
    }
    ${({ theme }) =>
        theme.breakpoints.from.medium.css`
            margin-bottom: 1em;
            font-size: 1.1em;
            font-weight: 900;
    `}
    ${({ theme }) =>
        theme.breakpoints.from.large.css`
            margin-bottom: 1em;
            font-size: 1.1em;
            font-weight: 900;
    `}
`;

interface iChildrenNav {
    location: Location;
}

const ChildrenNav = ({ location }: iChildrenNav) => {
    const { can } = useHoyerAcl();
    const [selectedSection] = useStore(
        (state) => [state.selectedSection],
        shallow
    );

    const filteredChildrenTaps = useMemo(() => {
        const filteredSections = sections
            .map((section, index) => {
                if (section.permission && !can(section.permission)) {
                    return null;
                }

                if (
                    section.isTopLevel ||
                    section.group !== selectedSection.group ||
                    section.dontShowHeader
                ) {
                    return null;
                }

                return (
                    <ChildTab
                        key={index}
                        className={classNames({
                            active: location.pathname === section.url,
                        })}
                        onClick={() => {
                            navigate(section.url);
                        }}
                    >
                        {section.displayName}
                        <OpenOnboardingRequestBadge itemName={section.name} />
                    </ChildTab>
                );
            })
            .filter((item) => item !== null);

        return filteredSections.length === 1 ? null : filteredSections;
    }, [location.pathname, selectedSection]);

    return selectedSection.hasChildren || selectedSection.hasParent ? (
        <ChildrenTabs>{filteredChildrenTaps}</ChildrenTabs>
    ) : null;
};

export default ChildrenNav;
