import React, { useState } from "react";

import styled from "@emotion/styled";

import { apiDownloadProtected } from "../../../../../apiBindings";
import { toReadableDateTime } from "../../../../../utils/DateHelpers";
import Headline from "../../../../base/atoms/Headline";
import NewSelect from "../../../../shared/forms/NewSelect";
import Button from "../../../../shared/NewForms/Button";
import NewHeadline from "../../../../shared/NewHeadline";
import useStore, { iFuelPrices } from "../../Dashboard/store";
import Box from "../Box";
import FuelPriceTable from "./FuelPriceTable";

const SpaceBetween = styled.div`
    display: flex;
    gap: 25px;
    justify-content: space-between;
    margin-top: 10px;
    flex-direction: column;

    ${({ theme }) => theme.breakpoints.from.medium.css`
        flex-direction: row;
    `}
`;

const UpdatedAtText = styled.div`
    text-align: center;
`;

const Disclaimer = styled.div`
    padding-top: 10px;

    > span {
        font-weight: bold;
    }
`;

type SelectableMonths = "currentMonth" | "lastMonth";
type SelectableStates = {
    currentMonth: "currentMonth";
    lastMonth: "lastMonth";
};

const selected: SelectableStates = {
    currentMonth: "currentMonth",
    lastMonth: "lastMonth",
};

const options = [
    {
        value: "currentMonth",
        text: "Aktueller Monat",
    },
    {
        value: "lastMonth",
        text: "Letzter Monat",
    },
];

const getUpdatedAtText = (date: string): string => {
    const formattedDate = toReadableDateTime(date, true);
    return `Letzte Aktualisierung: ${formattedDate} Uhr`;
};

interface FuelPriceListProps {
    productName: string;
    documentDownloadRoute: string;
    priceSheet: iFuelPrices | null;
    disclaimerContent?: React.ReactNode;
    path: string;
}

const FuelPriceList: React.FC<FuelPriceListProps> = ({
    productName,
    documentDownloadRoute,
    priceSheet,
    disclaimerContent,
    ...props
}) => {
    const [getSelectedCustomer] = useStore((state) => [
        state.getSelectedCustomer,
    ]);
    const selectedCustomer = getSelectedCustomer();
    const [selectedMonth, setSelectedMonth] = useState<SelectableMonths>(
        selected.currentMonth
    );

    const download = () => {
        apiDownloadProtected(documentDownloadRoute)
            .then((res) => res.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `rechnungsbeileger_${productName}.pdf`;
                a.click();
                a.remove();
                setTimeout(() => window.URL.revokeObjectURL(url), 100);
            });
    };

    if (!selectedCustomer || !priceSheet) {
        return (
            <div {...props}>
                <Box>
                    <p>
                        Es konnten keine {productName} Preisdaten geladen
                        werden.
                    </p>
                </Box>
            </div>
        );
    }

    return (
        <div {...props}>
            <Box>
                <Headline>
                    Hoyer Card {productName} Preis Deutschland (netto) in
                    Euro/Liter*
                </Headline>
                <SpaceBetween>
                    <div>
                        <NewSelect
                            name="selectedMonth"
                            placeholder="Zeitraum auswählen"
                            onChange={(
                                e: React.ChangeEvent<HTMLSelectElement>
                            ) =>
                                setSelectedMonth(
                                    e.target.value as SelectableMonths
                                )
                            }
                            options={options}
                            value={selectedMonth}
                            minWidth={undefined}
                        ></NewSelect>
                    </div>

                    <div>
                        <Button small color="redOutline" onClick={download}>
                            Rechnungsbeileger herunterladen
                        </Button>
                        <UpdatedAtText>
                            {getUpdatedAtText(priceSheet.updatedAt)}
                        </UpdatedAtText>
                    </div>
                </SpaceBetween>
                <FuelPriceTable data={priceSheet[selectedMonth]} />
                {disclaimerContent && (
                    <Disclaimer>{disclaimerContent}</Disclaimer>
                )}
            </Box>
        </div>
    );
};

export default FuelPriceList;
