import React from "react";

import dayjs from "dayjs";

import { css } from "@emotion/core";
import styled from "@emotion/styled";

import colors from "../../../../../../utils/colors";
import { iFuelPriceListItem } from "../../../Dashboard/store";

const Wrapper = styled.div`
    overflow-x: auto;
`;

const Table = styled.table`
    width: 100%;
    margin-top: 10px;
    color: ${({ theme }) => theme.colors.blue.toString()};
    white-space: nowrap;
    border-collapse: collapse;

    td,
    th {
        padding: 8px;
        text-align: center !important;
        border: 1px solid ${({ theme }) => theme.colors.darkGrey.toString()};
    }

    tr:hover {
        background-color: ${({ theme }) => theme.colors.grey.toString()};
    }

    th {
        padding-top: 12px;
        padding-bottom: 12px;
        background-color: ${({ theme }) => theme.colors.grey.toString()};
    }
`;

type TableProps = {
    data: iFuelPriceListItem[];
};

const FuelPriceTable = ({ data }: TableProps) => {
    const highlightToday = (date: string) => {
        const todayDate = dayjs().format("DD.MM.YY");
        if (todayDate === date) {
            return css`
                color: ${colors.white.toString()} !important;
                background-color: ${colors.blue.toString()} !important;
            `;
        }
    };

    const sortedByDateDesc = (): iFuelPriceListItem[] => {
        if (!data) return [];
        return data.sort((a, b) => {
            const aDate = dayjs(b.date, "DD.MM.YY");
            const bDate = dayjs(a.date, "DD.MM.YY");
            return aDate.isAfter(bDate) ? 1 : -1;
        });
    };

    if (!data || !data.length) {
        return (
            <Wrapper>
                <Table>
                    <tbody>
                        <tr>
                            <td>Es konnten keine Daten geladen werden.</td>
                        </tr>
                    </tbody>
                </Table>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <Table>
                <thead>
                    <tr>
                        <th>WT</th>
                        <th>Datum</th>
                        <th>KW</th>
                        {Object.keys(data[0].data).map((header, key) => (
                            <th key={key}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sortedByDateDesc().map((row) => {
                        return (
                            <tr key={row.date} css={highlightToday(row.date)}>
                                <td>{row.weekday}</td>
                                <td>{row.date}</td>
                                <td>{row.week}</td>
                                {Object.keys(row.data).map(
                                    (key: string, index: number) => (
                                        <td key={index}>{row.data[key]}</td>
                                    )
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </Wrapper>
    );
};

export default FuelPriceTable;
