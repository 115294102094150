/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect, useState } from "react";

import { apiFetchProtected } from "../../../../../../../apiBindings";
import NewInput from "../../../../../../shared/forms/NewInput";
import LoadingIndicator from "../../../../../../shared/LoadingIndicator";
import Margin from "../../../../../../shared/Margin";
import Message from "../../../../../../shared/Message";
import Button from "../../../../../../shared/NewForms/Button";
import { UncontrolledNotification as Notification } from "../../../../../../shared/Notification";

export const types = {
    generateCode: "generateCode",
    unlockDirectly: "unlockDirectly",
    removeCustomerNumber: "removeCustomerNumber",
};

const UnlockForm = ({ type }) => {
    const [needsConfirmation, setNeedsConfirmation] = useState(false);
    const [email, setEmail] = useState("");
    const [customerNumber, setCustomerNumber] = useState("");
    const [formErrors, setFormErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [successData, setSuccessData] = useState(null);

    useEffect(() => {
        setNeedsConfirmation(false);
    }, [customerNumber]);

    const addFormError = (error) => {
        if (!formErrors.includes(error)) {
            setFormErrors([...formErrors, error]);
        }
    };

    const clearFormError = (error) => {
        setFormErrors(formErrors.filter((item) => item !== error));
    };

    const onSubmit = (event) => {
        event.preventDefault();

        if (formErrors.length === 0) {
            const errors = [];
            if (
                (type === types.unlockDirectly ||
                    type === types.removeCustomerNumber) &&
                email === ""
            ) {
                errors.push("email");
            }
            if (customerNumber === "") {
                errors.push("customerNumber");
            }
            if (errors.length > 0) {
                setFormErrors(errors);
                return;
            }
            // No errors, we can proceed.

            if (type === types.unlockDirectly) {
                unlock();
            } else if (type === types.generateCode) {
                generate();
            } else if (type === types.removeCustomerNumber) {
                remove();
            }
        }
    };

    /**
     * Send the unlock request.
     */
    const unlock = () => {
        sendRequest(
            {
                email,
                customerNumber,
            },
            "unlock-directly"
        );
    };

    /**
     * Send the code generation request.
     */
    const generate = () => {
        sendRequest(
            {
                customerNumber,
            },
            "generate-code"
        );
    };

    /**
     * Remove the customer number.
     */
    const remove = () => {
        sendRequest(
            {
                email,
                customerNumber,
            },
            "remove-customer-number"
        );
    };

    /**
     * Send the given data to the given endpoint.
     *
     * @param {*} data
     * @param {*} endpoint
     */
    const sendRequest = (data, endpoint) => {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        apiFetchProtected(`/hoyercard-portal/admin/${endpoint}`, {
            method: "POST",
            body: JSON.stringify({ ...data, force: needsConfirmation }),
        }).then((response) => {
            if (response.status === "ok") {
                setSuccess(true);
                if (response.data) {
                    setSuccessData(response.data);
                }
                setNeedsConfirmation(false);
                setLoading(false);
            } else if (response.status === "needs_confirmation") {
                setNeedsConfirmation(true);
            } else {
                setError(true);
                setErrorMessage(response.message);
            }
            setLoading(false);
        });
    };

    /**
     * Reset the form.
     */
    const reset = () => {
        setEmail("");
        setCustomerNumber("");
        setError(false);
        setErrorMessage("");
        setSuccess(false);
        setLoading(false);
    };

    if (success) {
        return (
            <Notification type="success">
                {type === types.unlockDirectly && (
                    <p>
                        Die Kundennummer {customerNumber} wurde mit dem Benutzer{" "}
                        {email} verknüpft.
                    </p>
                )}
                {type === types.generateCode && (
                    <>
                        <p>
                            Für die Kundennummer {customerNumber} wurde ein
                            Aktivierungscode erstellt.
                        </p>
                        <p>
                            Der Aktivierungscode lautet:{" "}
                            <strong>{successData}</strong>
                        </p>
                    </>
                )}
                {type === types.removeCustomerNumber && (
                    <p>
                        Die Verknüpfung der Kundennummer {customerNumber} mit
                        dem Benutzer {email} wurde aufgehoben.
                    </p>
                )}
                <Button color="redOutline" fullWidth onClick={reset}>
                    Neuer Eintrag
                </Button>
            </Notification>
        );
    }

    if (loading) {
        return <LoadingIndicator>Daten werden übermittelt...</LoadingIndicator>;
    }

    if (error) {
        return (
            <Notification type="error" position="relative">
                {errorMessage !== "" ? (
                    <p>{errorMessage}</p>
                ) : (
                    <p>Leider ist ein Fehler aufgetreten.</p>
                )}

                <Margin
                    Component={Button}
                    top="20"
                    onClick={() => setError(false)}
                >
                    Verstanden
                </Margin>
            </Notification>
        );
    }

    return (
        <form onSubmit={onSubmit}>
            {(type === types.unlockDirectly ||
                type === types.removeCustomerNumber) && (
                <NewInput
                    name="email"
                    placeholder="E-Mail"
                    validation="email"
                    required="true"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    onError={() => addFormError("email")}
                    onErrorSolved={() => clearFormError("email")}
                    forceError={formErrors.includes("email")}
                />
            )}
            <NewInput
                name="customerNumber"
                placeholder="Kundennummer"
                validation="integer"
                required="true"
                value={customerNumber}
                onChange={(event) => setCustomerNumber(event.target.value)}
                onError={() => addFormError("customerNumber")}
                onErrorSolved={() => clearFormError("customerNumber")}
                forceError={formErrors.includes("customerNumber")}
            />
            {needsConfirmation && (
                <Message color="grey">
                    Diese Kundennummer existiert nicht im Hoyer Card Portal.
                    Möglicherweise wurde diese noch nicht in das System
                    übernommen.
                </Message>
            )}
            <Margin top={5}>
                <Button fullWidth>
                    {needsConfirmation && "Trotzdem "}
                    {type === types.generateCode
                        ? "Code generieren"
                        : type === types.removeCustomerNumber
                        ? "Verknüpfung aufheben"
                        : "Verknüpfen"}
                </Button>
            </Margin>
        </form>
    );
};

export default UnlockForm;
