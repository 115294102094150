import React, { useState } from "react";

import { SubmitHandler, useForm } from "react-hook-form";

import styled from "@emotion/styled";
import shallow from "zustand/shallow";

import { errorAwareApiFetchProtected } from "../../../../../../apiBindings";
import { Permission, useHoyerAcl } from "../../../../../../hooks/useHoyerAcl";
import Headline from "../../../../../base/atoms/Headline";
import Backdrop from "../../../../../shared/Backdrop";
import { BlurOther } from "../../../../../shared/Blur";
import ReactHookInput from "../../../../../shared/forms/ReactHookInput";
import { ErrorWrapper } from "../../../../../shared/forms/ReactHookInput/ErroMessage";
import ModalPortal from "../../../../../shared/Modal/Portal";
import Button from "../../../../../shared/NewForms/Button";
import useSnackbar from "../../../../../shared/Snackbar/store";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.blue.toString()};
    ${({ theme }) =>
        theme.breakpoints.from.small.css`
           ${theme.paddings.containerOuter.left};
           ${theme.paddings.containerOuter.right};
    `}
`;

const Modal = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 500px;
    max-width: 90%;
`;

const ActionRow = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

type Inputs = {
    email: string;
    customerNumber: string;
};

type iApiCreateResponse = {
    message: string;
};

interface iProps {
    setIsCreating: (value: boolean) => void;
    isCreating: boolean;
    getData: () => void;
}

const Create = ({ setIsCreating, isCreating, getData }: iProps) => {
    const { can } = useHoyerAcl();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const { control, handleSubmit } = useForm<Inputs>({
        defaultValues: {
            email: "",
            customerNumber: "",
        },
        mode: "onSubmit",
    });

    const [notify] = useSnackbar((state) => [state.notify], shallow);

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        setLoading(true);
        setError("");
        await errorAwareApiFetchProtected<iApiCreateResponse>(
            `/hoyercard-portal/customer-admin/create`,
            {
                method: "POST",
                body: JSON.stringify({
                    email: data.email,
                    customerNumber: data.customerNumber,
                }),
            }
        )
            .then((response) => {
                notify("Erfolgreich", response.message);
                setIsCreating(false);
                getData();
            })
            .catch((error) => {
                setError(error.message);
                notify("Fehler", error.message, "error");
            })
            .finally(() => setLoading(false));
    };

    if (!can(Permission.CUSTOMER_ADMIN_CREATE)) {
        return <></>;
    }

    return (
        <ModalPortal>
            {<BlurOther blur={["Page"]} />}
            <Backdrop open={isCreating} className="backdrop">
                <Container className="backdrop">
                    <Modal>
                        <Headline>Administrator hinzufügen</Headline>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ReactHookInput
                                placeholder="E-Mail-Adresse"
                                name="email"
                                fullWidth
                                rules={{
                                    required: true,
                                    maxLength: 50,
                                }}
                                control={control}
                            />
                            <ReactHookInput
                                placeholder="Kundennummer"
                                name="customerNumber"
                                fullWidth
                                rules={{
                                    required: true,
                                    maxLength: 15,
                                }}
                                control={control}
                            />
                            {error && <ErrorWrapper>{error}</ErrorWrapper>}
                            <ActionRow>
                                <Button
                                    color="grey"
                                    loading={loading}
                                    onClick={() => setIsCreating(false)}
                                >
                                    Abbrechen
                                </Button>
                                <Button type="submit" loading={loading}>
                                    Hinzufügen
                                </Button>
                            </ActionRow>
                        </form>
                    </Modal>
                </Container>
            </Backdrop>
        </ModalPortal>
    );
};

export default Create;
